<template>
  <section v-if="cartData.quantity > 0" class="fixed_widget">
    <div
      class="d-none d-md-block sticky_scroll sidebar-cart order-summary-widget row vld-parent"
    >
      <loading :active="spinner" :is-full-page="false"> </loading>
      <div class="total col-12">
        <div class="order-summary-text">
          <div class="cart-widget-title">Sumar comanda</div>
          <div class="summary-line subtotal">
            <span class="foc-left sumarline-title">Total produse (cu TVA): </span>
            <span class="order-summary-items-price">
              <span class="ron-price money-int"
                >{{ cartData.position_total_price.price_value }}
                {{ cartData.currency }}</span
              >
              <span class="valuta-price money-int"
                >(
                {{ priceInEuro(cartData.position_total_price.price_value) }}
                &euro;)</span
              >
            </span>
            <div class="foc-clear"></div>
          </div>
          <div class="line-separator"></div>
          <div class="summary-line">
            <span class="foc-left sumarline-title">Transport:</span>
            <span v-if="cartData.shipping_type_id != null" class="order-summary-shipping-cost">
              <span v-if="cartData.shipping_price.old_price_value == 0">
                <span class="money-int ron-price">
                  GRATUIT
                </span>
              </span>
              <span v-else>
                <span class="money-int ron-price">
                  {{ cartData.shipping_price.old_price_value }} {{ cartData.currency }}
                </span>
              </span>
            </span>
            <div class="foc-clear"></div>
          </div>
          <div v-if="cartData.shipping_price.increase_price_value > 0" class="summary-line">
            <span class="foc-left sumarline-title">Taxa numerar la livrare:</span>
            <span class="order-summary-shipping-cost">
              <span class="money-int ron-price">
                {{ cartData.shipping_price.increase_price_value }} {{ cartData.currency }}
              </span>
            </span>
            <div class="foc-clear"></div>
          </div>
        </div>
      </div>

      <div class="total pret-total-holder col-12">
        <div class="order-summary-total">
          <div class="title foc-left">Pret total (cu TVA):</div>
          <p class="price order-summary-total-price">
            <!-- <span class="ron-price money-int">{{ cartProducts.total_price.price_with_tax }} LEI</span -->
            <span class="ron-price money-int"
              >{{ cartData.total_price.price_value }} {{ cartData.currency }}</span
            ><span class="money-int valuta-price"
              >( {{ priceInEuro(cartData.total_price.price_value) }} &euro; )</span
            >
          </p>
        </div>
      </div>

      <div class="total action-holder col-12">
        <!-- <a href="#">Spre finalizare comanda</a> -->
        <!-- <input
          type="submit"
          name="checkout"
          value="Spre finalizare comanda"
          class="btn btn-red final-order"
        /> -->
        <pf-button
          v-if="isCheckout"
          :class="'btn btn-default btn-green btn-large'"
          class="final-order"
          to="/finalizeaza-cos"
          >SPRE FINALIZARE COMANDA</pf-button
        >
      </div>
    </div>

    <div class="d-block d-md-none mobile-total">
      <div v-if="cartData.shipping_price.increase_price_value > 0" class="bg pt-0">
        <div class="total flex-column align-items-start pl-1 pt-2" style="height: 70px;">
            <div>Taxa numerar la livrare:</div>
            <div class="order-summary-shipping-cost">
              <span class="money-int ron-price">
                {{ cartData.shipping_price.increase_price_value }} {{ cartData.currency }}
              </span>
            </div>
        </div>
      </div>
      <div class="bg pt-0">
        <div class="total flex-column align-items-start pl-1 pt-2" style="height: 70px;">
            <div>Transport:</div>
            <div v-if="cartData.shipping_type_id != null" class="order-summary-shipping-cost">
              <span v-if="cartData.shipping_price.old_price_value == 0">
                <span class="money-int ron-price">
                  GRATUIT
                </span>
              </span>
              <span v-else>
                <span class="money-int ron-price">
                  {{ cartData.shipping_price.old_price_value }} {{ cartData.currency }}
                </span>
              </span>
            </div>
        </div>
      </div>
      <div class="final-price">
        <div class="col-xl-3 col-lg-4 col-md-4 col-4 total-label">Subtotal</div>
        <div class="col-xl-6 col-lg-5 col-md-4 col-4">TVA</div>
        <div class="col-xl-3 col-lg-3 col-4">Pret total (cu TVA)</div>
      </div>
      <div class="bg">
        <div class="total">
          <div class="col-xl-3 col-lg-4 col-md-4 col-4 total-label">
            <span class="ron-price"
              >{{ cartData.total_price.price_without_tax }} {{ cartData.currency }}</span
            >
            <span class="valuta-price"
              >(
              {{ priceInEuro(cartData.total_price.price_without_tax_value) }}
              &euro;)</span
            >
          </div>
          <div class="col-xl-6 col-lg-5 col-md-4 col-4">
            <span class="ron-price"
              >{{ cartData.total_price.tax_price }} {{ cartData.currency }}</span
            >
            <span class="valuta-price"
              >( {{ priceInEuro(cartData.total_price.tax_price_value) }} &euro; )</span
            >
          </div>
          <div class="col-xl-3 col-lg-3 col-md-4 col-4 total-value">
            <span class="ron-price">{{ cartData.total_price.price_value }} {{ cartData.currency }}</span>
            <span class="valuta-price"
              >( {{ priceInEuro(cartData.total_price.price_value) }} &euro; )</span
            >
          </div>
        </div>
      </div>
      <div class="cart-actions row">
        <div class="col-lg-6 col-md-12 col-12">
          <!-- <button class="btn btn-back">Continua cumparaturile</button> -->
          <pf-button :class="'btn btn-default btn-red btn-large'" @click="setScrollTo"
            >CONTINUA CUMPARATURILE</pf-button
          >
        </div>
        <div class="col-lg-6 col-md-12 col-12 right">
          <!-- <input
            type="submit"
            name="checkout"
            value="Spre finalizare comanda"
            class="btn btn-red final-order"
          /> -->
          <pf-button
            v-if="isCheckout"
            :class="'btn btn-default btn-green btn-large'"
            class=""
            to="/finalizeaza-cos"
            >SPRE FINALIZARE COMANDA</pf-button
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import PfButton from "@/components/buttons/PfButton.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { shopMixin } from "@/mixins/shopMixin";
import { generalMixin } from "@/mixins/generalMixin";

export default {
  name: "Summar",
  components: {
    PfButton,
    Loading,
  },
  computed: {
    ...mapGetters("shop", ["products", "cartData"]),
    spinner() {
      return this.$store.getters["shop/spinner"];
    },
    isCheckout() {
      return this.$route.path !== "/finalizeaza-cos";
    },
  },
  mixins: [shopMixin, generalMixin],
  mounted() {

  },
};
</script>

<style scoped>
.final-order {
  font-size: 12px !important;
  margin-bottom: 0 !important;
  height: 52px;
  line-height: 52px;
}

.fixed_widget {
  position: sticky;
  right: 0px;
  top: 70px;
  margin-bottom: 35px;
  margin-top: 70px;
}

.cart-page .fixed_widget {
  margin-top: 0px;
  margin-bottom: 0px;
}

@import "../../assets/css/cart.css";

.fixed_widget {
  padding-top: 0;
}

@media (max-width: 576px) {
  .total span.valuta-price {
    font-size: 12px;
  }
}
</style>
