<template>
  <section
    class="section breadcrumb-area pb-90 page-header bg-header-img"
    :class="{'header-w-title' : item.title}"
    :style="backgroundImage"
  >
    <div v-if="overlay" class="overlay bg-black alpha-50">
      <div class="container" v-if="item.title"><span class="header-title">{{ item.title }}</span></div>
    </div>
    <div class="container bread-container t-center">
      <div class="row bread-row">
        <div class="col-md-10 col-md-offset-1 col-xs-12 text-center">
          <div class="section-top-title">
            <!-- <h1 class="t-uppercase font-45">{{ this.page.title }}</h1> -->
            <!-- <ol class="breadcrumb"> -->
            <!-- <li><a href="{{ 'home'|page }}"></i>Home</a></li> -->
            <!-- {% if this.page.url matches '{^\/[^/]([^/]+)\/}' %} -->
            <!-- {% set pages = this.page.url|split('/') %} -->
            <!-- {% for subpage in pages %} -->
            <!-- {% if not loop.first and not loop.last %} -->
            <!-- {% if subpage|page %} -->
            <!-- {% set title = subpage|capitalize|replace({'-': " ", '_': " "}) %} -->
            <!-- <li><a href="{{ subpage|page }}">{{ title }}</a></li> -->
            <!-- {% endif %} -->
            <!-- {% endif %} -->
            <!-- {% endfor %} -->
            <!-- {% endif %} -->
            <!-- <li class="active">{{ this.page.title }}</li> -->
            <!-- </ol> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return { path: "/storage/app/media/produse.jpg" };
      },
    },
    overlay: {
      type: Boolean,
      default: true,
    },
  },
  // mounted() {
  //   console.log(this.item);
  // }
  created() {
    //console.log(this.item);
  },
  computed: {
    backgroundImage() {
      if(this.$route.path.split('/')[1] === 'produs') {
        return { background: "unset", height: '110px' };
      } else if (this.item.path) {
        return { background: "url(" + this.item.path + ") center no-repeat" };
      }
      return { background: "url('/storage/app/media/produse.jpg') center no-repeat" };
    },
  },
};
</script>

<style scoped>
.page-header {
  background-position: 50%;
  height: 346px;
}

.header-w-title {
  height: 430px;
}

section.page-header {
  padding: 0;
}

.page-header .overlay {
  display: block;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(25%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0.8))
  );
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0.8))
  );
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 100%);
  z-index: 0;
  height: 100%;
  opacity: 1;
}

.header-title {
  font-family: Raleway Bold;
  font-size: 40px;
  color: #fff;
  position: absolute;
  bottom: 60px;
}

@media (max-width: 991px) {
  .header-w-title {
    height: 320px;
  }
}

@media (max-width: 767px) {
  .page-header {
    height: 300px;
  }
  .header-w-title {
    height: 350px;
  }
  .header-title {
    width: 540px;
    font-size: 34px;
    text-align: center;
    width: 540px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  #listproducts .page-header,
  .without-banner .page-header {
    height: 0;
    display: none;
  }

  .page-header {
    height: 60px;
  }

  .header-w-title {
    height: 320px;
  }

  #blog-page .page-header,
  #post_page .page-header {
    height: 230px;
  }

  .header-title {
    width: 100%;
  }
}
</style>
